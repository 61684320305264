import React from "react";
import styles from "./NotFoundPage.module.scss";
import Link from "next/link";

export const NotFoundPage = () => (
  <div className={styles.container}>
    <main className={styles.main}>
      <h1 className={styles.title}>404</h1>
      <div className={styles.text}>
        <b>Страница не найдена</b>
      </div>
      <div className={styles.text}>
        Попробуйте перезагрузить страницу или{" "}
        <Link href="/">перейти на главную.</Link>
      </div>
    </main>
  </div>
);
